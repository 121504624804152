import React from 'react';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, Media } from 'reactstrap';
import { Link } from 'react-router-dom';


function About(props) {

    const partners = props.partners.map(partner => {
        return (
            <h5>{partner.name}</h5>
        );
    });

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/home">Home</Link></BreadcrumbItem>
                        <BreadcrumbItem active>About Us</BreadcrumbItem>
                    </Breadcrumb>
                    <h2>About Us</h2>
                    <hr />
                </div>
            </div>
            <div className="row row-content">
                <div className="col-sm-6">
                    <h3>Mission</h3>
                    <p style={{textAlign:"justify"}}><b>Seal Of Hope</b> is a 501 (c) (3) non-profit, non-governmental Corporation.
Our mission is to assist African immigrants families in their first steps to integration in the United States; to provide free healthcare and Clean water in rural areas throughout the African continent; to educate children and adults to the use of information technology, read and write English and provide them with computers connected to Internet; to support the agricultural activities by providing new techniques in growing crops and modern materials that will allow men and women working in farms to achieve higher productivity and better income. 
 < br />
 <br />  <h3>Vision</h3> 

 <b>Our vision </b>as stated in our mission statement, “Let us change the future” is fundamentally rooted in the belief that every human no matter where he or she is born can have a better future, better health and contribute to the development of the human race in general. At Seal of Hope, we believe that no one should be left behind just because of the geographic location of his or her birthplace, but should have the opportunity to grow and become a solution for this world in his or her birth region. 
 <br />  <br />
        <h3>Our Projects</h3>
1. To develop projects that fit the needs of new African immigrants to the United States; <br />
<br />
 
2. To develop proposals, projects and programs related to education,  information technology, women Empowerment through setting special training centers in technology, financial basics; <br />
<br />
 
3. To develop projects that will allow access to periodic free healthcare in rural areas of African countries for people without access to healthcare. The second part of this section will consist of projects that help equip healthcare centers and other hospitals in materials. <br />
<br />
 
4. To develop projects that help equipped farmers and organize them through education of best practices and techniques to increase productivity and maintain sustainability and durability in compliance with environment friendly principles. 
.
                    </p>
                </div>
                <div className="col-sm-6">
                    <Card>
                        <CardHeader className="bg-primary text-white"><h3>Facts At a Glance</h3></CardHeader>
                        <CardBody>
                            <dl className="row">
                                <dt className="col-6">Founded</dt>
                                <dd className="col-6">March 28, 2014</dd>
                                <dt className="col-6">No. of Pages in 2017</dt>
                                <dd className="col-6">563</dd>
                                <dt className="col-6">No. of Reviews in 2018</dt>
                                <dd className="col-6">4388</dd>
                                <dt className="col-6">Employees</dt>
                                <dd className="col-6">12</dd>
                            </dl>
                        </CardBody>
                    </Card>
                </div>
                <div className="col">
                    <Card className="bg-light mt-3">
                        <CardBody>
                            <blockquote className="blockquote">
                                <p className="mb-0">I will not follow where the path may lead, but I will go where there is no path, and I will leave a trail.</p>
                                <footer className="blockquote-footer">Muriel Strode,{' '}
                                    <cite title="Source Title">"Wind-Wafted Wild Flowers" -
                                    The Open Court, 1903</cite>
                                </footer>
                            </blockquote>
                        </CardBody>
                    </Card>
                </div>
            </div>
            <div className="row row-content">
                <div className="col-12">
                    <h3>Our Partners</h3>
                </div>
                <div className="col mt-4">
                    <Media list>
                        {partners}
                    </Media>
                </div>
            </div>
        </div>
    );
}

export default About;