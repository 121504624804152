export const PARTNERS = [{
        id: 0,
        name: "EmeraldPlanet",
        image: "/assets/images/bootstrap-logo.png",
        featured: false,
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
        id: 1,
        name: " Mobile Science Consortium LLC",
        image: "/assets/images/git-logo.png",
        featured: false,
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.!"
    },
    {
        id: 2,
        name: "Ansa Technologies",
        image: "/assets/images/mongo-logo.png",
        featured: false,
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
        id: 3,
        name: "Agriculture & Food ",
        image: "/assets/images/agri.jpg",
        featured: true,
        description: "The continuing growing population of Africa and its large young population are a great opportunity as well as challenge. Seal of Hope with its partners are intending to train youth in various regions in best techniques and crops growing, to supply seeds and build water facilities to help establish off season crops growing. We are going to support African Agriculture in the transformation of the production into finite or semi-finite products with the objective of adding value, supporting jobs growth in rural areas and maintaining young people in Africa for better productivity. This last aspect of our Agriculture project will help stop or reduce the high risk sea migration phenomenon. " 
        
    }
]