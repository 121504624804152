import React from 'react';
import {Card, CardImg, CardBody, CardText, CardTitle,Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';


	function RenderPageInfo({page}) {
		if (page) {
			return (
				<div className="col-md-5 m-1">
					<Card>
						<CardImg top src={page.image} alt={page.name} />
						<CardBody>
							<CardTitle>{page.name}</CardTitle>
							<CardText>{page.description}</CardText>
						</CardBody>
					</Card>
				</div>
			);
		}
		return <div></div>;
	}
		function RenderComments({comments}) {
		if (comments) {
			return (
				<div className="col-md-5 m-1">
					{/* <Card>
						<CardImg top src={comments.image} alt={comments.name} />
						<CardBody>
							<CardTitle>{comments.name}</CardTitle>
							<CardText>{comments.description}</CardText>
						</CardBody>
					</Card> */}
					<h4> Comments</h4>
					{comments.map((comment) => {
						return (
							<div key={comment.id}>
								<div> {comment.text} </div>
								<div>
									--{comment.author}{' '}
									{new Intl.DateTimeFormat('en-US', {
										year: 'numeric',
										month: 'short',
										day: '2-digit',
									}).format(new Date(Date.parse(comment.date)))}
								</div>
								<br></br>
							</div>
						);
					})}
				</div>
			);
		} else {
			return <div></div>;
		}
	}

function PageInfo(props){
	console.log(props)
		if (props.page) {
			return (
				<div className="container">
					<div className="row">
						<div className="col">
							<Breadcrumb>
								<BreadcrumbItem><Link to="/directory">Directory</Link></BreadcrumbItem>
								<BreadcrumbItem active>{props.page.name}</BreadcrumbItem>
							</Breadcrumb>
					<h2>{props.page.name}</h2>
					<hr />
				</div>
			</div>


				<div className="container">
					<div className="row">
						<RenderPageInfo page={props.page} />
						<RenderComments comments={props.comments} />
					</div>
				</div>
				</div>
			);

		}
		return <div />;
	}


export default PageInfo;
