
export const PAGES = [{
        id: 0,
        name: "Seal Of Hope's Festival",
        image: "/assets/images/sop1.jpg",
        elevation: 1233,
        featured: false,
        description: "1st Gala of Seal Of Hope October 2015 with my partners of ANSA Technologies of the water purification system. "
    },
    {
        id: 1,
        name: "Fight Against Malaria",
        image: "/assets/images/sope7.jpg",
        elevation: 877,
        featured: false,
        description: "Dr, Sam Lee Hancock of the EmeraldPlanet TV on behalf of Pulcra Chemical(inventor and manufacturer of the Skintex MR3 blankets used in the fight against Malaria, Dengue fever..etc)  at our 1st Gala in 2015."
    },
    {
        id: 2,
        name: "Women Empowerment & Education",
        image: "/assets/images/sop2.jpg",
        elevationit: 2901,
        featured: false,
        description: "Women & girl empowerment is one of the areas where efforts are truly needed to support the growing young population in Africa knowing that women represent the majority of the continent. Seal of Hope through its education program, training in technology and support in funding of small productive projects will not only help women and girls become autonomous financially but become more productive. In addition, basic education in both French and English will be offered to young boys and girls in rural areas. The implementation of these projects will help strengthen the infractures in villages and regions where we will be present. "
    },
    {
        id: 3,
        name: "Clean Water For All In Africa",
        image: "/assets/images/sope3.jpg",
        elevation: 42,
        featured: true,
        description: "Operation Clean water production in MBalmayo Cameroon followed by the training for the use of  the Water purification Machine AQN of Ansa Technologies. 2015. This is an example of Seal Of Hope’s is contributing is solving the clean water scarcity in Africa. With our project “Clean Water for All in Africa” we will supply different regions of the continent in off grid clean water production machines SQN HydroVolt."
    }
];